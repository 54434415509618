import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import Terms from '../../../components/OnboardingKYC/Terms';
import FormWrapperContainer from '../FormWrapperContainer';
import { acceptTerms, getTerms } from '../actions';
import onboardingKYCContainerHOC from '../hoc';
import {
  selectCddOnboarding,
  selectIdvCheckStatus,
  selectIdvStatus,
  selectIsGettingTerms,
  selectIsSecondApplicant,
  selectProductType,
  selectTerms,
} from '../selectors';
import { productTypeToTermsMap } from '../config';
import Summary from '../../../components/OnboardingKYC/Summary/Summary';
import WaitingScreen from '../../../components/OnboardingKYC/Terms/waitingScreen';
import { COMPLETED } from '../../../components/OnboardingKYC/IDVOpenApp/constants';

function TermsContainer(props) {
  const { isSecondApplicant, productType } = props;
  const termsType = productTypeToTermsMap.get(productType);

  const cddOnboarding = useSelector(selectCddOnboarding);
  const idvStatus = useSelector(selectIdvStatus);
  const idvCheckStatus = useSelector(selectIdvCheckStatus);

  const idvCompleted = idvStatus === COMPLETED;
  const idvCheckCompleted = idvCheckStatus === 'PASSED';

  // Check both idvStatus (from /idvstatus) and idvCheckStatus (from /currentitem), depending on which call is made when
  const showSummaryScreen = useMemo(
    () => cddOnboarding && (idvCompleted || idvCheckCompleted),
    [cddOnboarding, idvStatus],
  );

  const showWaitScreen = useMemo(
    () => cddOnboarding && !showSummaryScreen,
    [cddOnboarding, showSummaryScreen],
  );

  if (showWaitScreen) {
    return (
      <FormWrapperContainer portalBacking={!isSecondApplicant} noProgressBar>
        <WaitingScreen />
      </FormWrapperContainer>
    );
  }

  if (showSummaryScreen) {
    return (
      <FormWrapperContainer portalBacking={!isSecondApplicant} noProgressBar>
        <Summary
          terms={props.terms}
          isGettingTerms={props.isGettingTerms}
          getTerms={() => props.getTerms(termsType)}
          acceptTerms={() => props.acceptTerms(isSecondApplicant)}
        />
      </FormWrapperContainer>
    );
  }

  return (
    <FormWrapperContainer portalBacking={!isSecondApplicant} noProgressBar>
      <Terms
        terms={props.terms}
        isGettingTerms={props.isGettingTerms}
        getTerms={() => props.getTerms(termsType)}
        acceptTerms={() => props.acceptTerms(isSecondApplicant)}
      />
    </FormWrapperContainer>
  );
}

TermsContainer.propTypes = {
  getTerms: PropTypes.func.isRequired,
  terms: PropTypes.string.isRequired,
  productType: PropTypes.string.isRequired,
  isGettingTerms: PropTypes.bool.isRequired,
  acceptTerms: PropTypes.func.isRequired,
  isSecondApplicant: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  terms: selectTerms,
  isGettingTerms: selectIsGettingTerms,
  isSecondApplicant: selectIsSecondApplicant,
  productType: selectProductType,
});

const mapDispatchToProps = (dispatch) => ({
  getTerms: (type) => dispatch(getTerms(type)),
  acceptTerms: (forSecondApplicant) =>
    dispatch(acceptTerms(forSecondApplicant)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(onboardingKYCContainerHOC, withConnect)(TermsContainer);
